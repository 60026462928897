import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
import {
  AdditionalBenefitType,
  BenefitCategory,
  BenefitSection
} from '~/generated/api-clients-generated'

export default class BenefitDetailsMap {
  static async use(variant: string = null) {
    const { additionalBenefitOptions } = await AdditionalBenefits.use(variant)

    const benefitMap = computed(() => [
      {
        id: AdditionalBenefitType.Vision,
        name: 'Vision Coverage',
        getter: (quote: QuotedPlanVm) => quote.benefitDetails?.visionBenefit?.associatedBenefits
      },
      {
        id: AdditionalBenefitType.Hearing,
        name: 'Hearing Coverage',
        getter: (quote: QuotedPlanVm) => quote.benefitDetails?.hearingBenefit?.associatedBenefits
      },
      {
        id: AdditionalBenefitType.Dental,
        name: 'Dental Coverage',
        getter: (quote: QuotedPlanVm) => quote.benefitDetails?.dentalBenefit?.associatedBenefits
      },
      {
        id: AdditionalBenefitType.Fitness,
        name: 'FitnessBenefits',
        getter: (quote: QuotedPlanVm) => quote.benefitDetails?.fitnessBenefit?.associatedBenefits
      },
      {
        id: AdditionalBenefitType.Transportation,
        name: 'Transportation',
        getter: (quote: QuotedPlanVm) =>
          quote.benefitDetails?.transportationBenefit?.associatedBenefits
      },
      {
        id: AdditionalBenefitType.DomesticTravel,
        name: 'DomesticTravel',
        getter: (quote: QuotedPlanVm) => quote.benefitDetails?.travelBenefit?.associatedBenefits
      },
      {
        id: AdditionalBenefitType.OtcMedications,
        name: 'OverTheCounterMedications',
        getter: (quote: QuotedPlanVm) => quote.benefitDetails?.otcBenefit?.associatedBenefits
      }
    ])

    const getAllBenefits = (quote: QuotedPlanVm) => benefitMap.value
      .map((b) => ({
        ...b,
        ...additionalBenefitOptions.value.find((abo) => abo.value === b.id),
        ...quote.additionalBenefits.additionalBenefits.find((ab) => ab.name === b.name),
        details: b.getter(quote)
      }))

    const getDisplayedBenefits = (quote: QuotedPlanVm) =>
      getAllBenefits(quote)
        .filter((b) => quote.additionalBenefits.additionalBenefits.some((ab) => ab.name === b.name))

    return {
      benefitMap,
      getAllBenefits,
      getDisplayedBenefits
    }
  }
}
