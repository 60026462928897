<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import PxStarRating from '~/components/px/PxStarRating.vue'
  import { PlanType, SnpType } from '~/generated/api-clients-generated'
  import { EngineStore } from '~/stores/engine'
  import { SessionStore } from '~/stores/session'
  import { RxStore } from '~/stores/rx'

  const props = defineProps<{
    quote: QuotedPlanVm
    label: string
    id: string
  }>()

  const rxStore = RxStore.use()

  const { show, onHide } = DialogController.use()
  const { maybeDo } = ActionGuard.use()
  const { getId } = Id.use(props.id)
  const { track } = Analytics.use()
  const { route } = AppRoute.use()
  const session = SessionStore.use()

  const open = () => {
    show(props.id)
  }

  const { getComponentContent, getTooltipContent } = Cms.use()

  const { content } = await getComponentContent('QuoteCardScoreSidebar')
  const { content: contactCardContent } = await getComponentContent('HelperCardPageCompare')
  const noDocs = computed(() => props.quote.providerCoverage.providers.length === 0)

  const engine = EngineStore.use()

  const noDocsOnAnyPlan = computed(
    () => engine.availableQuotes.filter((x) => x.providerCoverage.inNetworkCount > 0).length === 0
  )

  const dims = computed(() => props.quote.recommendation.score?.retail?.dimensions)

  const { getDisplayedBenefits } = await BenefitDetailsMap.use()

  const { flag } = FeatureFlags.use()

  // cost elements are hard coded here as the JE cost score always considers these elements when calculating the estimated OOP
  const costScoreCostElements = [
    'plan_premiums',
    'drug_costs',
    'oop'
    // 'part_b_premium',
    // 'part_b_reduction'
  ]

  const displayedBenefits = computed(() => getDisplayedBenefits(props.quote))

  const onSidebarShown = () => {
    track('PlanFitSidebarShown', {
      planId: props.quote.medicareId,
      openedFrom: route.path
    })
  }

  const showBenefitNeedCoverageLink = computed(
    () => flag<boolean>('benefit-need-coverage-link').value
  )
  const showDoctorNeedCoverageLink = computed(
    () => flag<boolean>('doctor-need-coverage-link').value
  )

  const scoreComponents = computed(() => [
    {
      key: 'overall',
      value: props.quote.score
    },
    {
      key: 'doctors',
      value: noDocs ? props.quote.providerCoverage.inNetworkPercentage : 0,
      features: ['doctorCoverage']
    },
    {
      key: 'cost',
      value: dims.value?.realCost,
      features: ['oopTooltip']
    },
    {
      key: 'risk_protection',
      value: dims.value?.riskProtection,
      features: ['maxOopTooltip']
    },
    {
      key: 'medicare_star_ratings',
      value: ((props.quote.details.starRating || 0) / 5) * 100,
      features: ['starRating']
    }
  ])

  const contactCardPhoneNumber = computed(() => {
    switch (true) {
      case session.planType === PlanType.PDP:
        return contactCardContent.value?.ext.pdp_tfn
      case session.planType === PlanType.MAPD:
        return contactCardContent.value?.ext.mapd_tfn
      default:
        return contactCardContent.value?.ext.phone_number
    }
  })
  const noDoctorsMessage = computed(() => {
    if (_isNil(contactCardPhoneNumber.value)) {
      return content.value?.ext.doctors_none_covered_explanation
    }
    return content.value?.ext.doctors_none_covered_explanation.replace(
      '(855) 922-9051',
      contactCardPhoneNumber.value
    )
  })
</script>

<template>
  <HpAnchor class="my-2 cursor-pointer pb-0 font-semibold leading-none" @click.prevent="open">
    {{ label }}
  </HpAnchor>

  <HpSidebar :id="id" :title="content.ext.title" @show="onSidebarShown">
    <aside class="flex flex-col gap-y-4">
      <section class="component-section" v-for="{ key, value, features } in scoreComponents">
        <header :class="['component-section-header', key]">
          <h2 :class="['component-title', key]">
            {{ content.ext[`${key}_title`] }}
          </h2>
          <template v-if="key === 'doctors' && noDocs">
            <HpIcon name="lock" />
          </template>
          <template v-else-if="key === 'doctors' && noDocsOnAnyPlan">
            <span class="font-bold">No score</span>
          </template>
          <template v-else-if="key === 'medicare_star_ratings' && value === 0">
            <span class="font-bold">No score</span>
          </template>
          <template v-else>
            <QuotePlanScoreV2 :score="Math.floor(value)" size="small" />
          </template>
        </header>
        <HpButton
          v-if="key === 'doctors' && noDocs"
          variant="secondary"
          @click="() => maybeDo(() => show('update-doctors-sidebar'))"
          :event-data="() => ({ openedFrom: 'PlanFitSidebar' })"
        >
          <span class="flex items-center">
            <HpIcon name="add" class="mr-1 scale-[85%]" />
            Add your doctors
          </span>
        </HpButton>
        <div v-for="feature in features" :class="['feature', feature]">
          <template
            v-if="
              feature === 'doctorCoverage' && quote.providerCoverage.count > 0 && !noDocsOnAnyPlan
            "
          >
            <HpAccordionItem title="Doctors In-Network" class="coverage-disclosure">
              <template #header-detail>
                {{ quote.providerCoverage.inNetworkCount }} of
                {{ quote.providerCoverage.count }} covered
              </template>
              <ul class="coverage-list" v-for="dr in quote.providerCoverage.providers">
                <li :class="['coverage-item', { covered: !!dr.inNetwork }]">
                  <div>
                    <HpIcon
                      v-if="dr.inNetwork"
                      class="scale-[65%] stroke-success text-success"
                      name="check"
                    />
                    <HpIcon v-else class="text-danger" name="no" />
                    <span>{{ dr.name }}</span>
                  </div>
                  <div class="coverage-item-actions" v-if="showDoctorNeedCoverageLink">
                    <HpAnchor v-if="!dr.inNetwork">I need coverage for this doctor</HpAnchor>
                  </div>
                </li>
              </ul>
            </HpAccordionItem>
          </template>
          <template
            v-if="
              feature === 'doctorCoverage' &&
              quote.providerCoverage.providers.length > 0 &&
              noDocsOnAnyPlan
            "
          >
            <p class="text-sm">
              {{ noDoctorsMessage }}
            </p>
          </template>
          <template v-if="feature === 'oopTooltip'">
            <table class="costs-table min-w-full">
              <tbody>
                <tr class="min-w-full">
                  <td class="w-3/4">
                    <PxTooltip name="TotalEstimatedYearlyCost" text="Expected All-in Costs" />
                  </td>
                  <td>
                    <span class="ml-3 !font-bold">{{
                      $formatUSD(quote.getYearlyEstCost(costScoreCostElements))
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <PxTooltip
                      name="PlanPremiums"
                      :text="`Plan Premium (${$formatUSD(quote.details.premium)} x 12 months)`"
                      class="ml-4"
                    />
                  </td>
                  <td>
                    <span>{{ $formatUSD(quote.details.premium * 12) }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <PxTooltip
                      name="EstimatedDrugCosts"
                      text="Estimated Yearly Drug Costs"
                      class="ml-4"
                    />
                  </td>
                  <td v-if="rxStore.rxs?.length < 1">
                    <HpAnchor
                      class="font-semibold"
                      @click.prevent="() => maybeDo(() => show('update-drugs-sidebar'))"
                    >
                      Add Drugs
                    </HpAnchor>
                  </td>
                  <td v-else>
                    <span>{{ $formatUSD(quote.drugCost) }}</span>
                  </td>
                </tr>

                <tr v-if="quote.details.type != PlanType.PDP">
                  <td>
                    <PxTooltip
                      name="OutOfPocketMedicalCosts"
                      text="Estimated Out-of-Pocket Costs"
                      class="ml-4"
                    />
                  </td>
                  <td>
                    <span>{{ $formatUSD(quote.recommendation.medicalCost) }}</span>
                  </td>
                </tr>

                <!--                <tr v-if="quote.details.type != PlanType.PDP">-->
                <!--                  <td class="border-none p-0">-->
                <!--                    <PxTooltip-->
                <!--                      name="PartBPremiums"-->
                <!--                      :text="`Part B Premium (${$formatUSD(-->
                <!--                        quote.recommendation.partBPremium-->
                <!--                      )} x 12)`"-->
                <!--                      class="ml-4"-->
                <!--                    />-->
                <!--                  </td>-->
                <!--                  <td class="border-none p-0">-->
                <!--                    <span>{{ $formatUSD(quote.recommendation.partBPremium * 12) }}</span>-->
                <!--                  </td>-->
                <!--                </tr>-->

                <!--                <tr v-if="quote.details.type != PlanType.PDP">-->
                <!--                  <td class="border-none p-0">-->
                <!--                    <PxTooltip-->
                <!--                      name="PartBPremiumReduction"-->
                <!--                      :text="`Part B Reduction (${$formatUSD(-->
                <!--                        quote.recommendation.partBReduction-->
                <!--                      )} x 12)`"-->
                <!--                      class="ml-4"-->
                <!--                    />-->
                <!--                  </td>-->
                <!--                  <td class="border-none p-0">-->
                <!--                    <span>- {{ $formatUSD(quote.recommendation.partBReduction * 12) }}</span>-->
                <!--                  </td>-->
                <!--                </tr>-->
              </tbody>
            </table>
          </template>
          <template v-if="feature === 'maxOopTooltip'">
            <table class="costs-table min-w-full">
              <tbody>
                <tr class="min-w-full">
                  <td>
                    <PxTooltip name="MaxOutOfPocket" text="Maximum Out-of-Pocket" />
                  </td>
                  <td>
                    <span class="ml-3 !font-bold">{{
                      $formatUSD(quote.details.maxOutOfPocket)
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <p v-if="quote.snpType === SnpType.DualEligible">
              {{ content.ext['risk_protection_dsnp_explanation'] }}
            </p>
          </template>
          <template v-if="feature === 'starRating'">
            <span>Star rating</span>
            <PxStarRating :star-rating="quote.details.starRating!" />
          </template>
          <template v-if="feature === 'benefitsCoverage' && quote.additionalBenefits.count > 0">
            <HpAccordionItem title="Extra Benefits" class="coverage-disclosure">
              <template #header-detail>
                {{ quote.additionalBenefits.coveredCount }} of
                {{ quote.additionalBenefits.count }} covered
              </template>
              <ul class="coverage-list" v-for="ben in displayedBenefits">
                <li :class="['coverage-item', { covered: !!ben.covered }]">
                  <div>
                    <HpIcon
                      v-if="ben.covered"
                      class="scale-[65%] stroke-success text-success"
                      name="check"
                    />
                    <HpIcon v-else class="text-danger" name="no" />
                    <span
                      class="ants"
                      v-if="ben.covered"
                      @click="show(getId(`${ben.name}-benefit-details-sidebar`))"
                      >{{ ben.text }}</span
                    >
                    <span v-else>{{ ben.text }}</span>
                  </div>
                  <div class="coverage-item-actions" v-if="showBenefitNeedCoverageLink">
                    <HpAnchor v-if="!ben.covered">I need coverage for this benefit</HpAnchor>
                  </div>
                </li>
              </ul>
            </HpAccordionItem>
          </template>
        </div>
        <p>
          {{ content.ext[`${key}_description`] }}
        </p>
      </section>
    </aside>
  </HpSidebar>
</template>

<style scoped>
  .component-section {
    @apply flex w-full flex-col gap-y-4;

    .component-section-header {
      @apply flex h-16 w-full items-center justify-between rounded-heading bg-gray-200 px-4;

      &.overall {
        @apply bg-primary-100;
      }

      .component-title {
        @apply text-2xl;

        &.overall {
          @apply text-3xl;
        }
      }
    }

    .feature {
      @apply flex flex-col px-2;

      &.starRating {
        @apply w-full flex-row items-center justify-between text-xl font-semibold;
      }

      .costs-table {
        td {
          @apply border-none p-0;

          span {
            @apply font-semibold text-primary-900;
          }
        }
      }
    }

    p {
      @apply pl-2;
    }
  }
</style>
